import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React  from 'react'

function Business() {
  return (
    <>
        <div className="client-carousel pt-80 client-carousel__has-grey-background">
      
            <span className='align'>
            <div className="container"> 
            <div className="block-title text-center">
                        <h3>Our Statutory Auditor</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>

<div className="swiper-wrapper" style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
    <div className="swiper-slide statutory">
    <img src="assets/images/business/auditorLogo.png" alt="" />
    </div>
</div>


                </Swiper>
            </div>

            <div className="container mt-1">
            <div className="block-title text-center mb-5">
                        <h3>Tech Partners</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>

                  
<div className="swiper-wrapper" style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
    <div className="swiper-slide others">
        <img src="assets/images/business/b-6.jpg" alt=""  />
    </div>
</div>
                </Swiper>
            </div>

            <div className="container mt-1">
            <div className="block-title text-center mb-5">
                        <h3>Requlatory</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>

                    <div className="swiper-wrapper">
                   
                        {/* <SwiperSlide> */}
                        <div className="swiper-wrapper" style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
    <div className="swiper-slide Requlatory">
        <img src="assets/images/business/b-1.jpg" alt=""  />
    </div>
</div>
                        {/* </SwiperSlide>        */}
                    </div>
                </Swiper>
            </div>
         
            </span>
       
            <span className='align'>
            <div className="container mt-2">
            <div className="block-title text-center">
                        <h3>Insurance</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>

<div className="swiper-wrapper" style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
    <div className="swiper-slide row insurance">
        <img src="assets\images\business\b-5.jpg" alt=""  />
    </div>
</div>

                </Swiper>
            </div>

            <div className="container mt-2">
            <div className="block-title text-center">
                        <h3>Banking Partners</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>

<div className="swiper-wrapper" style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
    <div className="swiper-slide row Banking">
        <img src="assets/images/business/b-2.jpg" alt="" />
        <img src="assets/images/business/b-3.jpg" alt="" />
    </div>
</div>

                </Swiper>
            </div>
            </span>
            <span className='align'>
           
            <div className="container mt-2">
            <div className="block-title text-center">
                        <h3>Business Associates</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>

<div className="swiper-wrapper" style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
    <div className="swiper-slide row aBanking">
        <img src="assets/images/business/b-7.jpg" alt="" />
        <img className='ab' src="assets/images/business/b-8.png" alt="" />
        <img src="assets/images/business/b-9.jpg" alt="" />
    </div>
</div>

                </Swiper>
            </div>
            </span>

           

        </div>
    </>
  )
}

export default Business